/*******************************************/
/* Main CSS */
/*******************************************/

/* Section Details */

.section-details {
  background-color: var(--secondary-color);
  border-radius: 1rem;
  width: 40rem;
  box-shadow: 0px 1px 20px 12px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 1px 20px 12px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 1px 20px 12px rgba(0, 0, 0, 0.18);
}

.title-container {
  padding: 0 2rem;
  border-bottom: 1px solid var(--grey-color);
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--main-color);
  text-align: center;
}

.details-container {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-search {
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  border: 1px solid var(--grey-color);
  outline: none;
}

.input-search:focus,
.input-search:active {
  border-color: var(--main-color);
}

.btn-currentLocation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  border: none;
  background-color: var(--main-color);
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 0.1s ease;
}

.btn-currentLocation:hover {
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  border: none;
  background-color: #0092fa;
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 0.1s ease;
}

.current-location-icon {
  color: var(--secondary-color);
}

/* Section Weather */

.weather-icon {
  width: 10rem;
}

.weather-temperature {
  font-size: 5rem;
  line-height: 1;
}

.weather-condition {
  font-size: 2rem;
}

.weather-city {
  font-size: 2rem;
}

.weather-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-bottom: 0;
  border-top: 1px solid var(--grey-color);
}

.feels-like-container {
  border-right: 1px solid var(--grey-color);
}

.weather-details-icon {
  font-size: 5rem;
}

.weather-text {
  font-size: 2rem;
  line-height: 1;
}

.weather-text-detail {
  font-size: 1.5rem;
}

/* Go Back Button */

.goBack-btn {
  border: none;
  background-color: transparent;
  color: var(--secondary-color);
  padding: 1rem 2rem;
  font-size: 2rem;
  border: 2px solid var(--secondary-color);
  border-radius: 10rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.goBack-btn:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  transition: all 0.2s ease;
}

.error {
  text-align: center;
  color: rgb(255, 0, 0);
}

/* Loading spinner */

.loading-spinner {
  margin: auto;
  z-index: 1;
  margin: auto;
  border: 1.6rem solid var(--grey-color);
  border-top: 1.6rem solid var(--main-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
