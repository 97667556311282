.section-details {
  background-color: var(--secondary-color);
  width: 40rem;
  border-radius: 1rem;
  box-shadow: 0 1px 20px 12px #0000002e;
}

.title-container {
  border-bottom: 1px solid var(--grey-color);
  padding: 0 2rem;
}

.title {
  color: var(--main-color);
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
}

.details-container {
  flex-direction: column;
  padding: 1rem 2rem;
  display: flex;
  position: relative;
}

.input-search {
  text-align: center;
  border: 1px solid var(--grey-color);
  border-radius: .5rem;
  outline: none;
  padding: 1rem;
  font-size: 2rem;
}

.input-search:focus, .input-search:active {
  border-color: var(--main-color);
}

.btn-currentLocation {
  text-align: center;
  background-color: var(--main-color);
  color: var(--secondary-color);
  cursor: pointer;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 1rem;
  font-size: 2rem;
  transition: all .1s;
  display: flex;
}

.btn-currentLocation:hover {
  text-align: center;
  color: var(--secondary-color);
  cursor: pointer;
  background-color: #0092fa;
  border: none;
  border-radius: .5rem;
  padding: 1rem;
  font-size: 2rem;
  transition: all .1s;
}

.current-location-icon {
  color: var(--secondary-color);
}

.weather-icon {
  width: 10rem;
}

.weather-temperature {
  font-size: 5rem;
  line-height: 1;
}

.weather-condition, .weather-city {
  font-size: 2rem;
}

.weather-details {
  width: 100%;
  border-top: 1px solid var(--grey-color);
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 0;
  display: grid;
}

.feels-like-container {
  border-right: 1px solid var(--grey-color);
}

.weather-details-icon {
  font-size: 5rem;
}

.weather-text {
  font-size: 2rem;
  line-height: 1;
}

.weather-text-detail {
  font-size: 1.5rem;
}

.goBack-btn {
  color: var(--secondary-color);
  border: none;
  border: 2px solid var(--secondary-color);
  cursor: pointer;
  background-color: #0000;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-size: 2rem;
  transition: all .2s;
}

.goBack-btn:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  transition: all .2s;
}

.error {
  text-align: center;
  color: red;
}

.loading-spinner {
  z-index: 1;
  border: 1.6rem solid var(--grey-color);
  border-top: 1.6rem solid var(--main-color);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: auto;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.a6e8e74a.css.map */
